<template>
  <div
    class="tw-container mt-5 fb-merchorder-page"
    style="max-width: 1200px; margin: auto"
  >
    <NRMerchOrder></NRMerchOrder>
  </div>
</template>

<script>
// components
import NRMerchOrder from '@/components/noRegistration/NR_MerchOrder.vue'

export default {
  components: { NRMerchOrder },
  created() {
    console.log(`storeId: ${this.$route.params.storeId}`)
  },
}
</script>